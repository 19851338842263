import React, { useContext } from 'react';
import classNames from 'classnames';
import { Nav, Navbar } from 'react-bootstrap';
import { navbarBreakPoint } from 'config';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import { NavbarVerticalMenu } from './NavbarVerticalMenu';
import routes from 'routes/routes';
import { checkPermission } from '../../../services/utils';

const NavbarVertical = () => {
  const { profile } = useContext(AppContext);
  const {
    config: { showBurgerMenu }
  } = useContext(AppContext);

  const filterChildRoutes = (children) => {
    return children.filter((child) => checkPermission(profile, child.permission));
  };
  return (
    <Navbar expand={navbarBreakPoint} className={classNames('navbar-vertical', {})} variant="light">
      <div className="d-flex align-items-center ps-2">
        <div className={'d-flex justify-content-center align-content-center spacing-logo-helper'}>
          <Logo at="navbar-vertical" />
        </div>
      </div>
      <Navbar.Collapse in={showBurgerMenu}>
        <div className="navbar-vertical-content scrollbar ps-2">
          <Nav className="flex-column" as="ul">
            <NavbarVerticalMenu
              routes={routes?.reduce((acc, route) => {
                let hasPermission = checkPermission(profile, route?.permission);

                if (!hasPermission && route?.children) {
                  hasPermission =
                    hasPermission ||
                    route?.children?.map((subRoute) => checkPermission(profile, subRoute?.permission)).filter((p) => p)
                      .length > 0;
                }
                const isValidType = route?.types?.includes(profile?.type);
                if (hasPermission || isValidType) {
                  let newRoute = { ...route };
                  if (route?.children) {
                    newRoute.children = filterChildRoutes(route.children, profile?.permissions);
                  }
                  acc.push(newRoute);
                }
                return acc;
              }, [])}
            />
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
