import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import SoftBadge from './common/SoftBadge';
import { Loader } from '@mantine/core';

const PaymentStatusBadge = ({ paymentStatus }) => {
  if (paymentStatus === 'Transaction succeeded') {
    return (
      <SoftBadge pill bg={'success'} className="fs--1 px-1 py-1">
        <FontAwesomeIcon icon={'check'} transform="shrink-2" />
        &nbsp;Paid &nbsp;
      </SoftBadge>
    );
  }

  if (paymentStatus === 'Transaction declined') {
    return (
      <SoftBadge pill bg={'danger'} className="fs--1 px-1 py-1">
        Declined
        <FontAwesomeIcon icon={'ban'} transform="shrink-2" />
      </SoftBadge>
    );
  }

  if (paymentStatus === null) {
    return (
      <SoftBadge pill bg={'warning'} className="fs--1 px-1 py-1">
        <Loader size="xs" />
        &nbsp;Pending
      </SoftBadge>
    );
  }

  return null;
};

export default PaymentStatusBadge;

PaymentStatusBadge.propTypes = {
  paymentStatus: PropTypes.string
};
