/*eslint camelcase: ["error", {allow: ["next_page","num_pages"]}]*/
import * as _ from 'lodash';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import { AsyncPaginate } from 'react-select-async-paginate';
import Select, { components } from 'react-select';
import { capitalizeFirstLetter } from '../../utils';
import { useAxios } from '../../hooks/useAxios';
import classNames from 'classnames';
import CreatableSelect from 'react-select/creatable';
import FalconCloseButton from '../common/FalconCloseButton';

export const CustomMenuList = (props) => {
  const options = props?.options;
  const setValue = props?.setValue;
  const onMenuClose = props?.selectProps?.onMenuClose;
  const menuHeaderStyle = {
    padding: '8px 12px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  };

  const onSelectAll = () => {
    setValue(options);
    onMenuClose && onMenuClose();
  };
  const onDeselectAll = () => {
    setValue([]);
    onMenuClose && onMenuClose();
  };
  return (
    <components.MenuList {...props}>
      <div style={menuHeaderStyle}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelectAll();
            return false;
          }}
        >
          Select all
        </a>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDeselectAll();
            return false;
          }}
        >
          Deselect all
        </a>
      </div>
      {props.children}
    </components.MenuList>
  );
};
CustomMenuList.propTypes = {
  children: PropTypes.any,
  setValue: PropTypes.func,
  selectProps: PropTypes.any,
  options: PropTypes.any
};
export const SimpleSelectField = ({
  label,
  options,
  defaultValue,
  helpText,
  disabled,
  required = false,
  onChangeValue = null,
  excludedItems = [],
  className = 'mb-2',
  style = {},
  isMulti = false,
  isClearable = false,
  ...props
}) => {
  const [{ value, name }, { ...meta }, { setValue }] = useField(props);

  let option = isMulti
    ? options?.filter((op) => value?.includes(op?.value))
    : options?.find((op) => `${op.value}` === `${value}`);

  if (option === undefined) {
    option = null;
  }
  const capitalizedLabel = capitalizeFirstLetter(label);
  const elementId = `id_${name}`;

  options = options.filter(
    (op) =>
      !excludedItems.map((excluded) => parseInt(excluded)).includes(parseInt(op.value)) ||
      parseInt(value) === parseInt(op.value)
  );

  return (
    <div className={className} style={style}>
      {label && (
        <div className="d-flex align-items-center">
          <label htmlFor={name} className="text-capitalize">
            {capitalizedLabel} {required && <RequiredMarker />}
          </label>
        </div>
      )}
      <Select
        id={elementId}
        className="react-select-container"
        classNamePrefix="custom-react-select"
        required={required}
        options={options}
        components={{
          // Conditionally include or exclude the MenuList component
          ...(isMulti ? { MenuList: CustomMenuList } : {})
        }}
        placeholder={`Select ${capitalizedLabel}`}
        value={option}
        style={{ zIndex: 9000 }}
        defaultValue={defaultValue}
        onChange={(selectedValue) => {
          const currentValue = isMulti ? selectedValue?.map((selected) => selected?.value) : selectedValue?.value;
          setValue(currentValue);
          onChangeValue && onChangeValue(selectedValue);
        }}
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable={isClearable}
      />
      <div className="d-flex flex-column justify-content-between">
        <FormikError name={name} meta={meta} />
        {helpText ? <small className="text-muted">{helpText}</small> : null}
      </div>
    </div>
  );
};

SimpleSelectField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.any,
  isMulti: PropTypes.any,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  onChangeValue: PropTypes.any,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.object,
  excludedItems: PropTypes.array
};

const AsyncSelectField = ({
  urlEndpoint,
  isMulti = false,
  isClearable = false,
  label,
  params,
  helpText,
  disabled,
  placeholder,
  required = false,
  creatable = false,
  onChangeValue = null,
  excludedItems = [],
  strict = false,
  onLoadItems = null,
  className = 'mb-2',
  allowNull = false,
  emptyLabel = ' -- not selected -- ',
  ...props
}) => {
  const [{ value, name }, meta, { setValue }] = useField(props);
  const key = [JSON.stringify({ name, value, params })];
  const { axios, abortRequest } = useAxios();
  const [options, setOptions] = useState([]);
  const [createdOptions, setCreatedOptions] = useState([]);
  const elementId = `id_${name}`;

  const loadInitialValue = async () => {
    const config = {
      params: {
        ...params,
        ...(value ? { selected: value } : {})
      }
    };
    const {
      data: { results }
    } = await axios.get(urlEndpoint, config);
    const options = sanitizeOptions(results);
    setOptions(options);
    sanitizeValues(options);
    onLoadItems && onLoadItems(options);
  };

  const SelectComponent = creatable ? CreatableSelect : AsyncPaginate;

  const paramsJson = JSON.stringify(params);

  const sanitizeOptions = (options) => {
    const uniqueOptions = [];
    for (const op of [...options, ...createdOptions]) {
      if (!uniqueOptions.find((t) => t.value === op.value)) {
        if (!excludedItems.includes(op.value) || value === op.value) {
          uniqueOptions.push(op);
        }
      }
    }
    return uniqueOptions;
  };

  const sanitizeValues = (options) => {
    // Filter current values against valid options
    if (!strict) return;

    // This will contain new proposed strict value
    let newValue;

    // Either an array of values or a single value
    if (isMulti) {
      let actualValues = value;
      if (!_.isArray(value) && !_.isNil(value)) {
        actualValues = [value];
        setValue(actualValues);
      }
      newValue = actualValues?.filter((currentValue) =>
        options?.find((currentOption) => currentOption.value === currentValue)
      );
    } else {
      newValue = options?.find((currentOption) => currentOption.value === value);
    }

    // Change values only if they differ to avoid some weird infinite loops
    if (JSON.stringify(value) !== JSON.stringify(newValue)) {
      setValue(newValue);
    }
  };

  const loadOptions = async (search, loadedOptions, { page = 1 }) => {
    try {
      const {
        data: { results, num_pages, page: currentPage, next_page }
      } = await axios.get(urlEndpoint, {
        params: {
          ...params,
          q: search,
          page,
          ...(value ? { selected: value } : {})
        }
      });
      const newOptions = sanitizeOptions([...options, ...results]);
      setOptions(newOptions);
      sanitizeValues(options);
      const hasMore = next_page ? true : currentPage < num_pages;
      const nextPage = next_page || currentPage + 1;
      return {
        options: [...(allowNull ? [{ label: emptyLabel, value: null }] : []), ...sanitizeOptions(results)],
        hasMore: hasMore,
        additional: {
          page: nextPage
        }
      };
    } catch (error) {
      // This condition ensures we do not continuously retry the API call,
      // which would result in an infinite loop if the endpoint is unreachable or returns a 403 Forbidden response.
      if (error.response && error.response.status === 403) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: 0
          }
        };
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    loadInitialValue().catch(console.error);
    return abortRequest;
  }, [value, paramsJson]);

  const onCreateOption = useCallback(async (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setCreatedOptions([...createdOptions, newOption]);
    setValue(inputValue);
  }, []);

  const sanitizedOptions = useMemo(() => {
    return sanitizeOptions(options);
  }, [options, createdOptions]);

  const option = useMemo(
    () =>
      isMulti
        ? sanitizedOptions?.filter((op) => {
            const currentValue = _.isArray(value) ? value : [value];
            return currentValue?.map((i) => `${i}`)?.includes(`${op?.value}`);
          })
        : sanitizedOptions?.find((op) => `${op.value}` === `${value}`),
    [value, sanitizedOptions]
  );
  const extraOptions = creatable ? { onCreateOption, options: sanitizedOptions } : {};
  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        {label && (
          <label htmlFor={name} className="text-capitalize">
            {label} {required && <RequiredMarker />}
          </label>
        )}
      </div>

      <SelectComponent
        key={key}
        isMulti={isMulti}
        required={required}
        placeholder={placeholder ? placeholder : `Select ${label}`}
        isClearable={isClearable}
        isDisabled={disabled}
        value={value && option ? option : null}
        loadOptions={loadOptions}
        components={{
          ...(isMulti ? { MenuList: CustomMenuList } : {})
        }}
        additional={{ page: 1 }}
        id={elementId}
        className={classNames('react-select-container', {
          'select-multiple': isMulti
        })}
        classNamePrefix="custom-react-select"
        onChange={(selectedValue) => {
          const currentValue =
            allowNull && selectedValue === null
              ? null
              : isMulti
              ? selectedValue?.map((selected) => selected?.value)
              : selectedValue?.value;
          setValue(currentValue);
          onChangeValue && onChangeValue(selectedValue);
        }}
        {...extraOptions}
      />

      <div className="d-flex flex-column justify-content-between">
        <FormikError name={name} meta={meta} />
        {helpText ? <small className="text-muted">{helpText}</small> : null}
      </div>
    </div>
  );
};

AsyncSelectField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.any,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  urlEndpoint: PropTypes.string,
  selectedOptions: PropTypes.array,
  params: PropTypes.any,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  excludedItems: PropTypes.any,
  onChangeValue: PropTypes.any,
  className: PropTypes.any,
  strict: PropTypes.bool,
  allowNull: PropTypes.any,
  emptyLabel: PropTypes.any,
  creatable: PropTypes.any,
  onLoadItems: PropTypes.func
};

export default AsyncSelectField;

export const SortSelectField = ({ onSortBy, sortBy, options, className = 'me-1', ...props }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Select
        options={options}
        placeholder="Order by"
        name="order_by"
        menuPlacement="auto" // This ensures that the menu is placed automatically to avoid overflowing the viewport
        menuPortalTarget={document.body} // This allows the menu to appear outside of its parent container
        menuPosition="fixed" // Ensures that the menu position is fixed for z-index to work as expected
        classNamePrefix="custom-select" // Add a custom class prefix for better styling control
        className={className}
        styles={{
          indicatorSeparator: (provided) => ({
            ...provided,
            marginTop: 4,
            marginBottom: 4
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            padding: 4,
            height: 29
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            minHeight: 20,
            padding: 4,
            height: 29
          }),
          control: (provided) => ({
            ...provided,
            minWidth: '180px',
            minHeight: 20,
            height: 29,
            fontSize: 12
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999
          })
        }}
        value={
          sortBy?.length > 0
            ? options.find((option) => option.value === `${sortBy[0]?.desc ? '-' : ''}${sortBy[0]?.id}`)
            : null
        }
        onChange={(option) => {
          onSortBy([
            {
              id: `${option.value.replaceAll('-', '')}`,
              desc: option.value.includes('-')
            }
          ]);
        }}
        {...props}
      />
      {sortBy?.length > 0 && (
        <div
          className="search-box-close-btn-container"
          style={{
            right: '45px', // Position the close button to the right inside the select field
            top: '50%', // Vertically center the button
            transform: 'translateY(-50%)', // Adjust for centering
            position: 'absolute'
          }}
        >
          <FalconCloseButton
            size="sm"
            noOutline
            onClick={() => {
              onSortBy([]);
            }}
          />
        </div>
      )}
    </div>
  );
};

SortSelectField.propTypes = {
  className: PropTypes.string,
  onSortBy: PropTypes.func,
  sortBy: PropTypes.any,
  options: PropTypes.any
};
