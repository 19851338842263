/*eslint camelcase: ["error", {allow: ["tax_details","card_payment_status","is_active","last_order","updated_at", "created_at", "average_risk_score","shipping_cost","new_order","action_req",]}]*/

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Thumbnail } from '../../components/common/Avatar';
import itemNotFound from '../../assets/img/item-not-found.jpg';
import { formatDatetime, formatPhoneNumber, moneyFormat, ORDER_STATUS } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from '../../components/common/SoftBadge';
import { toast } from 'react-toastify';
import { CustomEmailModal } from '../order/CustomEmailModal';
import { DATE_FORMAT, preventDefault } from '../../utils';
import { InternalNote } from '../../components/InternalNote';
import { LoadingView } from '../../components/LoadingView';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useDocumentTitle } from '../../hooks/usePageTitle';
import moment from 'moment';
import AppContext from '../../context/Context';
import { USER_PERMISSION_DISCOUNT, USER_PERMISSION_PRODUCT, USER_PERMISSION_ORDER } from '../../services/constants';
import IconButton from '../../components/common/IconButton';
import CountUp from 'react-countup';
import { COUNT_UP_DURATION } from '../../helpers/utils';
import { Badge } from '@mantine/core';
import { RiskInsightsWidget } from '../../components/RiskInsightWidget';
import CurrencyFormat from 'react-currency-format';
import PaymentStatusBadge from '../../components/PaymentStatusBadge';
import { OrderStatusIndicator } from '../../components/OrderStatusIndicator';
import { MarketingWidget } from '../order/widgets/MarketingWidget';
import TaxDetails from '../../components/TaxDetails';

const CustomerView = () => {
  const { id } = useParams();
  const [width] = useWindowSize();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [customer, setCustomer] = useState(null);

  const [lastUpdate, setLastUpdate] = useState(null);
  const [isShowingEmail, setIsShowingEmail] = useState(false);
  const [showCustomerEmailModal, setShowCustomerEmailModal] = useState(false);
  const { profile } = useContext(AppContext);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(false);
  };

  const loadCustomerData = async () => {
    try {
      const { data } = await axios.get(`customer/${id}/`);
      setCustomer(data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useDocumentTitle([['Customer', 'View'], customer?.name]);

  useEffect(() => {
    loadCustomerData().catch(console.error);

    return abortRequest;
  }, []);

  // use
  const internalNoteComponent = useMemo(() => {
    return (
      <>
        <InternalNote
          hideDisabled={true}
          lastUpdate={lastUpdate}
          params={{
            customer: customer?.id
          }}
          disabled={!customer?.email}
        />
      </>
    );
  }, [customer, lastUpdate]);
  const breakpointWidth = 992;

  const getCustomerFilter = (customerId) => {
    const params = new URLSearchParams();
    params.append('customer', customerId);
    ORDER_STATUS.map((status) => status.value)
      .filter((status) => status !== 'canceled')
      .map((status) => {
        params.append('order_status', status);
      });
    return params.toString();
  };

  return (
    <div>
      {isLoading ? <LoadingView /> : null}
      {customer ? (
        <>
          <CustomEmailModal
            params={{ customer: id }}
            show={showCustomerEmailModal}
            onHide={() => setShowCustomerEmailModal()}
            onSuccess={(data) => {
              setShowCustomerEmailModal(false);
              setCustomer({ ...customer, email: data?.email });
              setLastUpdate(new Date());
              setIsShowingEmail(true);
            }}
          />
          <div className="py-3 d-flex align-items-center flex-wrap">
            <Link to="/customer" className="btn btn-outline-secondary">
              <FontAwesomeIcon icon="arrow-left" />
            </Link>
            <div className="ps-3 flex-fill">
              <div className="d-flex flex-wrap align-items-center">
                <strong className="cursor-pointer">{customer?.name}</strong>
              </div>
              <small>{customer?.location}</small>
            </div>
          </div>
          <div className={classNames('row', { 'd-none': !customer })}>
            <div className="col-12 col-lg-8">
              <div className="col-12 col-lg-12">
                <div className="card mb-3">
                  <div className="card-body py-5 py-sm-3">
                    <div className="row g-5 g-sm-0">
                      <div className="col-sm-3">
                        <div className="border-end-sm border-300 border-end border-1">
                          <div className="text-center me-1">
                            <h6 className="text-700">
                              <Badge color="blue" size="md" variant="dot">
                                Total Spent
                              </Badge>
                            </h6>
                            <h4 className="fw-normal text-700">
                              <CurrencyFormat
                                id="total"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={customer?.order_revenue || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="border-end-sm border-300 border-end border-1">
                          <div className="text-center">
                            <Link
                              className="fw-normal font-monospace text-decoration-none"
                              to={`/order?${getCustomerFilter(customer.id)}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-700">
                                <Badge color="yellow" size="md" variant="dot">
                                  Total Orders
                                </Badge>
                              </h6>
                              <h4 className="fw-normal text-700 position-relative">
                                <CountUp end={customer?.order_count} duration={COUNT_UP_DURATION} />
                              </h4>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="border-end-sm border-300 border-end border-1">
                          <div className="text-center">
                            <Link
                              className="fw-normal font-monospace text-decoration-none"
                              to={`/order?customer=${customer?.id}&order_status=completed`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-700">
                                <Badge color="green" size="md" variant="dot">
                                  Completed
                                </Badge>
                              </h6>
                              <h4 className="fw-normal text-700">
                                <CountUp end={customer?.order_count_completed} duration={COUNT_UP_DURATION} />
                              </h4>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="border-end-sm border-300">
                          <div className="text-center">
                            <Link
                              className="fw-normal font-monospace text-decoration-none"
                              to={`/order?customer=${customer?.id}&order_status=canceled`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-700">
                                <Badge color="red" size="md" variant="dot">
                                  Problems
                                </Badge>
                              </h6>
                              <h4 className="fw-normal text-700">
                                <CountUp end={customer?.order_count_canceled} duration={COUNT_UP_DURATION} />
                              </h4>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3 animate__animated animate__fadeIn animate__delay-1">
                <div className="card-header">
                  <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <div className="d-flex justify-content-between">
                      <div className="py-1">
                        <h5 className="mb-0 pb-0">
                          {customer?.last_order?.length === 1
                            ? 'Last Order Placed'
                            : customer?.last_order?.length > 1
                            ? `Last ${customer?.last_order?.length} Orders Placed`
                            : 'No Recent Orders'}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {customer?.last_order?.map((order, orderIndex) => (
                  <div key={`order-block-${orderIndex}`}>
                    <div className="d-flex justify-content-end me-3">
                      {profile?.permissions?.includes(USER_PERMISSION_ORDER) && (
                        <div className="text-right mt-auto ml-auto">
                          <Link
                            className="fw-normal font-monospace"
                            to={`/order/${order?.id}/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton iconClassName="fs--2 me-1" variant="falcon-default" size="sm" icon="info">
                              <small>Order Details</small>
                            </IconButton>
                          </Link>
                        </div>
                      )}
                    </div>
                    <Card.Body key={`order-body-${orderIndex}`}>
                      {order?.items?.map((item, index) => (
                        <div key={`item-${index}`} className="order-item mb-3 pb-3 flex-wrap">
                          <div className={'d-flex flew-wrap align-items-start'}>
                            <div className="d-flex w-100 align-items-center pe-1">
                              <div className="pe-4">
                                <div className="position-relative d-table">
                                  <Thumbnail
                                    className="border text-center"
                                    src={item?.image ? item?.image : itemNotFound}
                                  />
                                  <span
                                    style={{ top: 100 }}
                                    className={
                                      'position-absolute top-0 start-100 translate-middle badge rounded-pill ' +
                                      'bg-secondary'
                                    }
                                  >
                                    {item.quantity}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-fill flex-grow-1">
                                <div>
                                  {profile?.permissions?.includes(USER_PERMISSION_PRODUCT) ? (
                                    <a href={`/product/${item.product}`} target="_blank" rel="noreferrer">
                                      {item.name}
                                    </a>
                                  ) : (
                                    <a target="_blank" rel="noreferrer">
                                      {item.name}
                                    </a>
                                  )}
                                  <div>
                                    <small>
                                      {moneyFormat(item.price)} &times; {item.quantity}
                                    </small>
                                  </div>

                                  {item.engraving_lines ? (
                                    <div
                                      style={{
                                        wordBreak: 'break-all'
                                      }}
                                    >
                                      <small>
                                        <strong>Engraving Lines: </strong>
                                        {item.engraving_lines}
                                      </small>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="justify-content-end flex-column d-flex">
                              <div className="d-flex mt-sm-0 align-items-center justify-content-center">
                                <div>
                                  <div className="text-end">
                                    <div>
                                      <span>{moneyFormat(item.subtotal)}</span>
                                    </div>
                                  </div>
                                  {item.discount ? (
                                    <div className="text-end">
                                      <small className="text-danger">-{moneyFormat(item?.discount)}</small>
                                    </div>
                                  ) : null}
                                  {item.engraving_subtotal ? (
                                    <div>
                                      <small>+{moneyFormat(item.engraving_subtotal)}</small>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {order?.status_label && (
                        <div className="d-flex justify-content-between">
                          <div>
                            <strong>Status</strong>
                          </div>
                          <div>
                            <OrderStatusIndicator status={order?.status_label} substatus={order?.substatus_label} />
                          </div>
                        </div>
                      )}
                      {order?.financial_status && (
                        <div className="d-flex justify-content-between mt-1">
                          <strong>Financial Status</strong>
                          <PaymentStatusBadge paymentStatus={order?.card_payment_status} />
                        </div>
                      )}
                      {order?.subtotal !== null ? (
                        <div
                          className="d-flex justify-content-between mt-3"
                          style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.05)' }}
                        >
                          <strong>Subtotal</strong>
                          <span>{moneyFormat(order?.subtotal)}</span>
                        </div>
                      ) : null}
                      {order?.shipping_cost !== null ? (
                        <div className="d-flex justify-content-between">
                          <strong>
                            Shipping
                            {order?.last_order_shipping_type_name ? (
                              <small>({order?.last_order_shipping_type_name})</small>
                            ) : null}
                          </strong>
                          <span>{moneyFormat(order?.shipping_cost)}</span>
                        </div>
                      ) : null}
                      {order && <TaxDetails order={order} />}
                      {order?.discount_cost ? (
                        <div className="d-flex justify-content-between">
                          <strong>
                            Discount
                            {order?.discount_code ? (
                              <span>
                                {order?.discount ? (
                                  <small>
                                    {profile?.permissions?.includes(USER_PERMISSION_DISCOUNT) ? (
                                      <small>
                                        <a
                                          href={
                                            order?.discount_is_gift_card
                                              ? `/gift-card/${order?.discount}/`
                                              : `/discount/${order?.discount}/`
                                          }
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                                          {order?.discount_code}
                                        </a>
                                      </small>
                                    ) : (
                                      <small>
                                        <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                                        {order?.discount_code}
                                      </small>
                                    )}
                                  </small>
                                ) : (
                                  <small> ({order?.discount_code})</small>
                                )}
                              </span>
                            ) : null}
                          </strong>
                          <span className="text-danger">-{moneyFormat(order?.discount_cost)}</span>
                        </div>
                      ) : null}
                      {order?.total !== null ? (
                        <div
                          className="d-flex justify-content-between mt-3 pt-3 mb-3"
                          style={{ borderTop: 'solid 1px rgba(0, 0, 0, 0.05)' }}
                        >
                          <strong>Total</strong>
                          <span>{moneyFormat(order?.total)}</span>
                        </div>
                      ) : null}
                    </Card.Body>
                  </div>
                ))}
              </div>

              {width >= breakpointWidth ? <div className="mt-5 mb-3">{internalNoteComponent}</div> : null}
            </div>
            <div className="col-12 col-lg-4">
              <Card className="mb-3 mb-3 animate__animated animate__fadeIn animate__delay-2">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="pb-0 bt-0 mb-0 mt-0">Customer Information</h5>
                  </div>
                </div>
                <Card.Body className="pb-1 pt-1 mb-1 bg-light">
                  <div>
                    <strong className="fw-bold">
                      <small>Name</small>
                    </strong>
                    <div>{customer?.name}</div>
                  </div>
                  <div>
                    <strong className="fw-bold">
                      <small>Phone Number</small>
                    </strong>
                    <div>
                      <a href={`tel:${customer?.phone_number}`}>{formatPhoneNumber(customer?.phone_number)}</a>
                    </div>
                  </div>
                  {customer?.birthdate ? (
                    <div>
                      <strong className="fw-bold">
                        <small>Birth Date</small>
                      </strong>
                      <div>{moment(customer?.birthdate, 'YYYY-MM-DD').format(DATE_FORMAT)}</div>
                    </div>
                  ) : null}
                  <div>
                    <strong className="fw-bold">
                      <small>Email</small>
                    </strong>
                    <div>
                      {isShowingEmail ? <a href={`mailto:${customer?.email}`}>{customer?.email}</a> : customer?.email}
                      <br />
                      {customer?.email && !isShowingEmail ? (
                        <button
                          type="button"
                          className="ms-auto btn btn-outline-primary btn-sm"
                          onClick={(e) => {
                            preventDefault(e);
                            setShowCustomerEmailModal(true);
                          }}
                        >
                          Show Email
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <hr />
                  <div className="mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <strong className="fw-bold">
                        <small>Shipping Address</small>
                      </strong>
                      <a
                        className="btn btn-link btn-sm"
                        onClick={(e) => {
                          preventDefault(e);
                          navigator.clipboard
                            .writeText([customer?.recipient_information].filter((part) => part?.length > 0).join(' '))
                            .catch(console.error);
                          toast.success('Copied Address To Clipboard');
                        }}
                      >
                        <FontAwesomeIcon icon="copy" />
                      </a>
                    </div>
                    <div>{customer?.recipient_information}</div>
                  </div>
                  {customer?.order_first_created_at && (
                    <>
                      <hr />
                      <div>
                        <strong className="fw-bold">
                          <small>Customer Since</small>
                        </strong>
                        <div>{formatDatetime(customer.order_first_created_at)}</div>
                      </div>
                    </>
                  )}
                  {customer?.order_last_created_at && (
                    <div>
                      <strong className="fw-bold">
                        <small>Customer Last Modified</small>
                      </strong>
                      <div>{formatDatetime(customer.order_last_created_at)}</div>
                    </div>
                  )}
                </Card.Body>
              </Card>

              <RiskInsightsWidget riskScore={customer?.average_risk_score} />

              {customer?.brands?.length > 0 && (
                <Card className="mb-3 mb-3 animate__animated animate__fadeIn animate__delay-4">
                  <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="pb-0 bt-0 mb-0 mt-0">Brands List</h5>
                    </div>
                  </div>
                  <Card.Body className="bg-light">
                    <div className="d-flex w-100">
                      <Row>
                        <Col>
                          {customer?.brands?.slice(0, 10).map((brand, index) => (
                            <React.Fragment key={`main-${index}`}>
                              <small>
                                <SoftBadge key={`main-${index}`} id={`main-${index}`} bg="primary" className="me-2">
                                  {brand}
                                </SoftBadge>
                              </small>
                            </React.Fragment>
                          ))}

                          {!isCollapsed &&
                            customer?.brands?.slice(10).map((brand, index) => (
                              <>
                                <small>
                                  <SoftBadge key={`other-${index}`} bg="primary" className="me-2">
                                    {brand}
                                  </SoftBadge>
                                </small>
                              </>
                            ))}

                          {isCollapsed && customer?.brands?.length > 10 && (
                            <>
                              <small>
                                <Link to="#" onClick={toggleCollapse}>
                                  <SoftBadge bg="secondary" className="me-2">
                                    <FontAwesomeIcon icon="angle-right" className="me-1" />
                                    Show More
                                  </SoftBadge>
                                </Link>
                              </small>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              )}
              <MarketingWidget order={customer} />
            </div>
          </div>

          <div>{width < breakpointWidth ? internalNoteComponent : null}</div>
        </>
      ) : null}
    </div>
  );
};

export default CustomerView;
