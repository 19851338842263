import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import TextField from '../../../components/form/TextField';

import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import { SubmitFooter } from '../../../components/form/SubmitFooter';
import { useAxios } from '../../../hooks/useAxios';
import { useDocumentAction, useDocumentTitle } from '../../../hooks/usePageTitle';
import { LoadingView } from '../../../components/LoadingView';
import AppContext from '../../../context/Context';
import { USER_PERMISSION_SETTING_EDIT, USER_TYPE_ADMIN } from '../../../services/constants';
import { SwitchField } from '../../../components/form/CheckboxField';

const PortfolioCreateUpdate = () => {
  const { id } = useParams();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [instance, setInstance] = useState(null);
  const { profile } = useContext(AppContext);

  const isReadOnly = id && !profile.permissions.includes(USER_PERMISSION_SETTING_EDIT);
  const loadUserData = async () => {
    const { data } = await axios.get(`portfolio/${id}/`);
    setInstance(data);
  };

  useDocumentTitle([['Portfolio', useDocumentAction()], instance?.name]);

  const navigate = useNavigate();
  const validate = Yup.object({
    name: Yup.string().required().label('Name')
  });
  const loadData = async () => {
    setIsLoading(true);
    if (id) {
      await loadUserData();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, []);

  const initialValues = id ? instance : {};

  return (
    <div className="mt-3 animate animate__fadeIn">
      <Formik
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const url = id ? `portfolio/${id}/` : 'portfolio/';
            const { data } = await axios.post(url, values);

            toast.success(
              <>
                Portfolio <Link to={`settings/portfolio/${data.id}`}>{data.name}</Link>{' '}
                {id ? 'was updated successfully' : 'was created successfully'}
              </>
            );
            navigate(`/settings/portfolio/`);
          } catch ({ response }) {
            setErrors(response?.data);

            if (response?.data?.message) {
              toast.error(response?.data?.message, {
                theme: 'colored'
              });
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form
                className={classNames('', {
                  'd-none': isLoading
                })}
              >
                <Card>
                  <FalconCardHeader title={id ? 'Update Portfolio' : 'Create Portfolio'} />
                  <Card.Body className="bg-light">
                    <TextField label="Name" required={true} name="name" disabled={isSubmitting || isReadOnly} />
                    {id && profile?.type === USER_TYPE_ADMIN && (
                      <>
                        <SwitchField
                          name="go_cardless_active"
                          label="GoCardless Integration"
                          disabled={isSubmitting || isReadOnly}
                          required={false}
                          className="mt-3"
                        />
                        <div>
                          <small className="text-warning">
                            Enabling this option at the Portfolio level will automatically activate it for all
                            associated Brands. However disabling it will not impact the settings at the brand level.
                          </small>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>

                {!isReadOnly && <SubmitFooter backUrl={'/settings/portfolio/'} isSubmitting={isSubmitting} />}
              </Form>
              {isLoading && (
                <div className="py-3">
                  <LoadingView width={1} />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PortfolioCreateUpdate;
