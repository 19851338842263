export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const ENVIRONMENT_TYPE = process.env.REACT_APP_ENVIRONMENT_TYPE;
export const DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_FORMAT = 'MM/DD/YYYY';

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const capitalizeFirstLetter = (text) => {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const IMAGE_LARGE_ERROR_MESSAGE = 'The file you have provided exceeds the maximum allowable size of 20MB';
export const FILE_UPLOAD_MAX_SIZE = 20;

export const MAX_PRICE_VALUE = 100000;
export const MAX_PRICE_ERROR = 'Amount must be no more than $100,000';
export const priceMax = async (value) => {
  if (value !== null) {
    return parseFloat(value) <= MAX_PRICE_VALUE;
  }
  return false;
};

export const chunkString = (str) => {
  if (str) {
    return str.match(/.{1,10000}/g);
  }
  return str;
};

export const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const getFileUrl = (obj) => {
  return obj instanceof File ? URL.createObjectURL(obj) : obj;
};

export const DISCONNECTED_ERROR = 'Information were updated successfully. Sign in to continue';

export const asRGBA = (data) => {
  const hex = [
    Number(data.r).toString(16).padStart(2, '0'),
    Number(data.g).toString(16).padStart(2, '0'),
    Number(data.b).toString(16).padStart(2, '0')
  ];

  const alpha = Number(parseInt(data.a * 255))
    .toString(16)
    .padStart(2, '0');
  hex.push(alpha);
  return `#${hex.join('')}`;
};

export const asRGB = (data) => {
  const hex = [
    Number(data.r).toString(16).padStart(2, '0'),
    Number(data.g).toString(16).padStart(2, '0'),
    Number(data.b).toString(16).padStart(2, '0')
  ];

  return `#${hex.join('')}`;
};

export const navigateToError = (response, navigate) => {
  if ('response' in response) {
    response = response?.response;
  }
  if (response?.status === 404) {
    navigate('/error/404');
  } else if (response?.status === 403) {
    navigate('/error/403');
  } else if (response?.status === 500) {
    navigate('/error/500');
  }
};

export const GOOGLE_PROPERTY_ID_EMAIL_SANDBOX =
  'starting-account-gdx274jpks37@bottle-nexus-san-1677156270245.iam.gserviceaccount.com';
export const GOOGLE_PROPERTY_ID_EMAIL_PRODUCTION =
  'starting-account-8lwq0f3ehdo9@bottle-nexus-1677155651881.iam.gserviceaccount.com';
export const GOOGLE_PROPERTY_ID_EMAIL =
  ENVIRONMENT_TYPE === 'sandbox' ? GOOGLE_PROPERTY_ID_EMAIL_SANDBOX : GOOGLE_PROPERTY_ID_EMAIL_PRODUCTION;

export const slugify = (value) => {
  value = String(value);
  // eslint-disable-next-line no-control-regex
  value = value.normalize('NFKD').replace(/[^\x00-\x7F]/g, '');
  value = value.toLowerCase().replace(/[^\w\s-]/g, '');
  value = value.replace(/[-\s]+/g, '-');
  return value;
};

const getContent = (blockContent) => {
  /* With this we process code like this: color: 'red';\nfont-size: 10px; into {color: 'red', 'font-size': '10px'} */
  const body = {};
  blockContent.split(';').forEach((instruction) => {
    let [name, value] = instruction.split(':');
    name = name.trim();
    if (name.length > 0) {
      body[name?.trim()] = value?.trim();
    }
  });
  return body;
};

const normalizeSelector = (selector) => {
  // we just remove any ; from the selector, in case we miss on the end of line like this };
  return selector.replace(';', '').trim();
};

export const cssProcessor = (cssCode) => {
  if (!cssCode) {
    return {};
  }
  cssCode = cssCode.replace(/"/gi, "'");

  const dictionary = {};

  const importRegex = /@import[^;]+;/g;
  if (cssCode.match(importRegex, '')) {
    dictionary['@imports'] = cssCode.match(importRegex, '');
  }

  cssCode = cssCode.replace(importRegex, '');

  // We will check char by char the content, so I need a way to buffer information
  let buffer = '';

  // selector and block to main content. If you put `body{color: "red"}` it will be stored here. But also a @media
  let selector = '';
  let block = null;

  // In case we have a @media on main block we will need to store somewhere else the blocks found along the code
  let innerSelector = null;
  let innerBlock = null;

  cssCode
    .trim()
    .split('')
    .forEach((char) => {
      if (char === '{') {
        // 	block open
        if (block) {
          innerBlock = {};
          innerSelector = buffer;
        } else {
          selector = buffer;
          block = {};
        }

        buffer = '';
      } else if (char === '}') {
        // block close
        if (innerBlock) {
          block[normalizeSelector(innerSelector)] = getContent(buffer);
        } else {
          if (Object.keys(block).length === 0) {
            block = getContent(buffer.trim());
          }
          dictionary[normalizeSelector(selector)] = block;
          selector = null;
          block = null;
        }
        innerSelector = null;
        innerBlock = null;

        buffer = '';
      } else {
        buffer += char;
      }
    });

  return dictionary;
};

export const COUNTRY_LIST = [
  { value: 'US', label: 'United States' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'DE', label: 'Germany' }
];

export const PLATFORM_3JMS = 0;
export const PLATFORM_SHIPSTATION = 1;
export const PLATFORM_LIST = [
  { value: PLATFORM_3JMS, label: '3JMS' },
  { value: PLATFORM_SHIPSTATION, label: 'ShipStation' }
];
export const COUNTRY_AREA_CODES = {
  GB: '+44',
  DE: '+49',
  US: '+1'
};
