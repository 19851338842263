/*eslint camelcase: ["error", {allow: ["stage","status_display","skip_all","stage_id","stage_position","provider_name","stage_name"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAxios } from '../../../hooks/useAxios';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { asFormData } from '../../../services/utils';
import classNames from 'classnames';
import { FILE_UPLOAD_MAX_SIZE, IMAGE_LARGE_ERROR_MESSAGE } from '../../../utils';
import ToggleCard from '../../../components/form/ToggleCard';
import { InternalNote } from '../../../components/InternalNote';
import { LoadingView } from '../../../components/LoadingView';
import AppContext, { KanbanContext } from '../../../context/Context';
import { USER_PERMISSION_BRAND_EDIT } from '../../../services/constants';
import * as Yup from 'yup';
import { IMAGE_EXTENSION_TYPE_MESSAGE, validateImageExtension } from '../../../utils/validation';
import LoadingButton from '../../../components/common/LoadingButton';
import { SecretSection } from '../sections/SecretSection';
import { StageItemsUpdate } from '../StageItemsUpdate';
import { MultipleFileField } from '../MultipleFileField';
import { ContactSection } from '../sections/ContactSection';
import { InformationFragment } from '../sections/InformationFragment';
import { JiraTaskSection } from '../sections/JiraTaskSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BrandKanbanModal = () => {
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const { profile } = useContext(AppContext);
  const { columns, setColumns, selectedTask, setSelectedTask, updateBrand } = useContext(KanbanContext);
  const isReadOnly = selectedTask && !profile?.permissions?.includes(USER_PERMISSION_BRAND_EDIT);
  const [brand, setBrand] = useState(null);

  const validate = Yup.object({
    email: Yup.string().email().label('Email').nullable(),
    name: Yup.string().required().label('Name'),
    photo: Yup.mixed()
      .test('fileSize', IMAGE_LARGE_ERROR_MESSAGE, (value) => {
        if (!(value instanceof File)) {
          return true;
        }
        const size = value?.size / 1024 / 1024;
        return parseInt(size) <= parseInt(FILE_UPLOAD_MAX_SIZE);
      })
      .test('type', IMAGE_EXTENSION_TYPE_MESSAGE, validateImageExtension),

    prefix: Yup.string().required().label('Prefix')
  });

  const loadData = async () => {
    setIsLoading(true);
    if (selectedTask) {
      try {
        const { data } = await axios.get(`brand/${selectedTask}/`);
        setBrand(data);
      } catch (e) {
        console.error(e);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (selectedTask) {
      loadData().catch(console.error);
    }
    return abortRequest;
  }, [selectedTask]);

  const initialValues = brand ? { ...brand, portfolios: brand?.portfolios?.map((p) => p.value) } : {};

  return (
    <Modal show={selectedTask} size="xl" onHide={() => setSelectedTask(null)}>
      <Formik
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={validate}
        initialValues={{ ...initialValues }}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            const url = selectedTask ? `kanban/brand/${selectedTask}/` : '/brand/';
            const payload = asFormData(values);
            if (values?.contacts) {
              payload.set('contacts', JSON.stringify(values?.contacts));
            }

            const fileFields = ['photo'];
            for (const field of fileFields) {
              if (!(payload.get(field) instanceof File) && payload.has(field) && payload.get(field) !== '') {
                payload.delete(field);
              }
            }
            payload.delete('stage');
            const params = { 'skip-full-update': true };
            const { data } = await axios.post(url, payload, { params });

            const newBrand = { ...brand, ...data };

            updateBrand(newBrand);

            setBrand(newBrand);

            toast.success(
              <>
                Brand <Link to={`/brand/${data?.id}/`}>{data?.name}</Link> was updated successfully
              </>
            );
          } catch ({ response }) {
            if (response?.data?.message) {
              toast.error(response?.data?.message, {
                theme: 'colored'
              });
            }
            setErrors(response?.data);
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <>
              {isLoading ? (
                <div className="py-3">
                  <LoadingView width={1} />
                </div>
              ) : (
                <>
                  <Form
                    className={classNames('kanban-modal-root animate__animated animate__fadeIn', {
                      'd-none': isLoading
                    })}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{brand?.name}</Modal.Title>
                    </Modal.Header>

                    <StageItemsUpdate
                      setBrand={(newBrand) => {
                        updateBrand(newBrand);
                        setFieldValue('stage', newBrand?.stage);
                      }}
                      brand={values}
                    />

                    <Modal.Body className="pb-0">
                      {brand?.id && brand?.has_order_last_month === false ? (
                        <div className="alert alert-danger d-flex align-items-start">
                          <FontAwesomeIcon icon="times-circle" className="text-danger fs-0 me-2" />
                          <div className="flex-1">
                            <h6 className="pb-0 mb-0">Notice: No orders were recorded for the previous month</h6>
                          </div>
                        </div>
                      ) : null}
                      <div className="d-flex flex-wrap justify-content-end">
                        <a href={`/product?brands=${brand?.id}&empty_stock=true`} target="_blank" rel="noreferrer">
                          Show out of Stock Products
                        </a>
                      </div>

                      {/* brand information */}
                      <InformationFragment />
                      {/* end of brand information */}

                      <ContactSection brand={brand} />

                      {selectedTask ? (
                        <Card className="mb-3">
                          <ToggleCard title="Documents" open={false}>
                            <Card.Body className="">
                              <MultipleFileField brandId={selectedTask} disabled={isSubmitting || isReadOnly} />
                            </Card.Body>
                          </ToggleCard>
                        </Card>
                      ) : null}

                      {brand ? (
                        <Card className="mb-3">
                          <ToggleCard title="Internal Notes" open={brand?.last_updated_at}>
                            <Card.Body className="">
                              {brand?.id ? (
                                <InternalNote
                                  hideDisabled={true}
                                  onAdd={(data) => {
                                    if (columns && setColumns) {
                                      let updatedKanbanState = columns;
                                      const taskToUpdate = updatedKanbanState
                                        .flatMap((column) => column.brands)
                                        .find((task) => task.id === brand?.id);
                                      if (taskToUpdate) {
                                        // eslint-disable-next-line camelcase
                                        taskToUpdate.updated_at = data?.results[0]?.created_at;
                                      }
                                      // Update the state with the modified kanbanState
                                      setColumns(updatedKanbanState);
                                    }
                                  }}
                                  params={{
                                    brand: brand?.id
                                  }}
                                  disabled={isSubmitting || isReadOnly}
                                />
                              ) : null}
                            </Card.Body>
                          </ToggleCard>
                        </Card>
                      ) : null}

                      <JiraTaskSection brand={brand} />

                      <SecretSection brand={brand} disabled={isSubmitting || isReadOnly} />
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                      <Button
                        disabled={isSubmitting || isReadOnly}
                        variant="default"
                        onClick={() => setSelectedTask(null)}
                        id="form-footer-back"
                      >
                        Close
                      </Button>

                      <LoadingButton
                        type="submit"
                        disabled={isSubmitting || isReadOnly}
                        isLoading={isSubmitting}
                        className="form-footer-submit"
                      >
                        Save
                      </LoadingButton>
                    </Modal.Footer>
                  </Form>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

BrandKanbanModal.propTypes = {
  columns: PropTypes.any,
  setColumns: PropTypes.func,
  selectedTask: PropTypes.any,
  setSelectedTask: PropTypes.func,
  updateBrand: PropTypes.func
};
